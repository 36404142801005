<view-header
  [label]="data?.orderDetail.orderNo"
  [backRoute]="{ label: 'Tellimused', route: '/orders'}"
></view-header>

<view-content bgColor="var(--app-bg-secondary)">
  <form class="flex flex-col gap-5x" [formGroup]="formGroup">
    <div class="card">
      <form>
        <div class="flex w-full">
          <owner-select
            for="owner"
            class="w-full"
            [options]="formData.ownerOptions"
          ></owner-select>
        </div>

        <form-field label="Staatus">
          <status-indicator
            [range]="6"
            [status]="data.orderDetail.status"
            enum="PurchaseOrderStatus"
            [description]="data.orderDetail.status | translateEnum : 'PurchaseOrderStatus'"
            [border]="true"
          ></status-indicator>
        </form-field>

        <button
          appButton
          size="medium"
          color="tertiary"
          class="min-w-full justify-between"
          label="Lisa kõik ostukorvi"
          iconPosition="right"
          icon="monochromeSaveCart"
        ></button>
      </form>
    </div>

    <div class="card gap-6x">
      <!-- <h4>{{data?.orderDetail.orderNo}}</h4> -->

      <form>
        <form-field label="Tarnija">
          <div class="flex flex-col gap-2-5x">
            <div class="flex items-center gap-5x">
              <icon-box icon="monochromeMaterial"></icon-box>
              <span class="bold"> {{data.orderDetail?.supplier?.name}} </span>
            </div>
          </div>
        </form-field>
        <form-field label="Tarne asukoht">
          <div class="flex flex-col gap-2-5x">
            <div class="flex items-center gap-5x">
              <icon-box icon="monochromeAddress"></icon-box>
              <span class="bold"> {{data.orderDetail?.location?.name}} </span>
            </div>
            <div class="ml-17x flex flex-col">
              <span> {{data.orderDetail?.location?.address}} </span>
            </div>
          </div>
        </form-field>

        <form-field label="Kontaktisik">
          <div class="flex flex-col gap-2-5x">
            <div class="flex items-center gap-5x">
              <icon-box icon="monochromeUser"></icon-box>
              <span class="bold">
                {{data.orderDetail?.contact?.name || '-'}}
              </span>
            </div>
            <div class="ml-17x flex flex-col">
              <a
                href="mailto:{{data.orderDetail?.contact?.email}}?subject={{data.orderDetail?.orderNo}}"
                class="bold w-max"
              >
                {{data.orderDetail?.contact?.email}}
              </a>
            </div>
          </div>
        </form-field>

        @let deliveryDate = data.orderDetail?.deliveryDate; @if(deliveryDate) {
        <form-field label="Soovitud tarneaeg">
          <div class="flex flex-col gap-2-5x">
            <div class="flex items-center gap-5x">
              <icon-box icon="monochromeDate"></icon-box>
              <span class="bold"> {{deliveryDate | displayDate}} </span>
            </div>
          </div>
        </form-field>
        } @let sentAt = data.orderDetail?.sentAt; @if(sentAt) {
        <form-field label="Tarnijale edastatud">
          <div class="flex flex-col gap-2-5x">
            <div class="flex items-center gap-5x">
              <icon-box icon="monochromeDate"></icon-box>
              <span class="bold">
                {{data.orderDetail?.sentAt | displayDate}}
              </span>
            </div>
          </div>
        </form-field>
        } @let comment = data.orderDetail?.comment; @if(comment) {
        <form-field label="Kommentaar">
          <div class="flex flex-col gap-2-5x">
            <div class="flex gap-5x">
              <icon-box icon="monochromeNote"></icon-box>
              <span class="bold"> {{comment || '-'}} </span>
            </div>
          </div>
        </form-field>
        }
      </form>

      <hr class="w-full border-grey-alt" />

      <div class="flex w-full flex-col gap-5x">
        <h5>Lisatud tooted ({{data.items?.length}})</h5>

        @for(product of data.items; track $index) {
        <order-product-card [data]="product"></order-product-card>
        }
      </div>

      <h5 class="flex w-full justify-between">
        <span> Tellimuse summa: </span>
        <span> {{orderTotal | priceValue}} </span>
      </h5>
      <button
        appButton
        size="medium"
        color="tertiary"
        class="min-w-full justify-between"
        label="Lisa kõik ostukorvi"
        iconPosition="right"
        icon="monochromeSaveCart"
      ></button>
    </div>
  </form>
</view-content>
