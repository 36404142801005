import { CurrencyPipe, JsonPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import moment from 'moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IconBoxComponent } from 'src/app/components/_elements/icon-box/icon-box.component';
import { StatusIndicatorComponent } from 'src/app/components/_elements/status/status-indicator/status-indicator.component';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ViewHeaderComponent } from 'src/app/components/_layout/view-header/view-header.component';
import { ButtonComponent } from 'src/app/components/form/button/button.component';
import { FormFieldComponent } from 'src/app/components/form/form-field/form-field.component';
import { OwnerSelectComponent } from 'src/app/components/form/select/owner-select/owner-select.component';
import { OrderCardComponent } from 'src/app/components/order/order-card/order-card.component';
import { OrderProductCardComponent } from 'src/app/views/protected/orders/order-product-card/order-product-card.component';
import { LxmDatePipe } from 'src/app/pipes/date.pipe';
import { PriceValuePipe } from 'src/app/pipes/price-value.pipe';
import { TranslateEnumPipe } from 'src/app/pipes/translate-enum.pipe';
import { OrdersService } from 'src/app/services/orders.service';

export const purchaseOrderResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const { orderId } = route.params || {};

  return inject(OrdersService).getPurchaseOrderData(orderId);
};

export const purchaseOrderFormDataResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(OrdersService).getPurchaseOrderFormData();
};

@Component({
  selector: 'app-purchase-order-view',
  templateUrl: './purchase-order.view.html',
  styleUrls: ['./purchase-order.view.scss'],
  standalone: true,
  imports: [
    ViewHeaderComponent,
    ViewContentComponent,
    OrderCardComponent,
    JsonPipe,
    ButtonComponent,
    FormFieldComponent,
    LxmDatePipe,
    SvgIconComponent,
    ReactiveFormsModule,
    OwnerSelectComponent,
    IconBoxComponent,
    StatusIndicatorComponent,
    TranslateEnumPipe,
    OrderProductCardComponent,
    PriceValuePipe,
  ],
  providers: [PriceValuePipe, CurrencyPipe],
  host: {
    id: 'view',
  },
})
export class PurchaseOrderComponent implements OnInit {
  public formGroup: FormGroup;

  public data;
  public formData;

  constructor(private _route: ActivatedRoute) {
    const { data, formData } = this._route.snapshot.data;
    this.formData = formData;
    console.log(data);
    this.data = data;

    this.formGroup = new FormGroup({
      owner: new FormControl(data.owner),
      status: new FormControl(data.status),
      deliveryDate: new FormControl(data.deliveryDate),
      deliveryAddress: new FormControl(data.delivery),
    });
  }

  public get orderTotal() {
    let total = 0;
    this.data.items.forEach((item) => {
      total += item.baseUnitBuyInPrice * item.amount;
    });

    return total;
  }

  ngOnInit() {}
}
